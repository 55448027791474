import React, { useState } from "react"
import { useMount } from "react-use"
import { css, keyframes } from "@emotion/core"
import Layout from "@app-components/layout/"
import Background from "@app-components/utils/background"
import Icon from "@app-components/utils/icon"
import data from "@data/demo/index.json"

const fadeIn = keyframes`
	from {
		opacity: 0;
		transform: translateY(10px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
`

const Demo = () => {
  const [calendlyLoaded, setCalendlyLoaded] = useState(false)
  const loadStyles = () => {
    const link = document.createElement("link")
    link.href = "https://assets.calendly.com/assets/external/widget.css"
    link.rel = "stylesheet"
    document.head.appendChild(link)
  }
  const loadJS = () => {
    const script = document.createElement("script")
    script.src = "https://assets.calendly.com/assets/external/widget.js"
    script.onload = () => {
      setCalendlyLoaded(true)
    }
    document.head.appendChild(script)
  }
  useMount(() => {
    loadStyles()
    loadJS()
  })
  return (
    <Layout getStartedButtons={["demo", "get-started"]} page="demo">
      <Background tagName="section" className="white bg-cover py5" type="red-single">
        <div className="px2 md-px3 max-width-4 mx-auto mt4">
          <h1 className="center mb3">Connect with us</h1>
          {data.options.map((option, index) => (
            <article
              style={{
                borderTop: index > 0 ? "1px solid rgba(255,255,255,0.37)" : "",
                animationDelay: `.${index * 2}s`
              }}
              className="py3 block md-flex items-center center md-left-align"
              css={css`
                border-top: ${index > 0 ? "1px solid rgba(255,255,255,0.37)" : ""};
                animation-delay: ${`.${index * 2}s`};
                opacity: 0;
                animation: ${fadeIn} 0.2s linear forwards;
              `}
              key={option.title}>
              <Icon size="64" className="inline-block" style={{ background: "white" }} icon={option.icon} />
              <div className="flex-auto pl0 md-pl3 pr0 md-pr4">
                <h2>{option.title}</h2>
                <p className="mb2 md-mb0 large-p-tag">{option.text}</p>
              </div>
              <button
                disabled={!calendlyLoaded}
                onClick={() => Calendly.showPopupWidget(option.calendar)}
                css={css`
                  box-shadow: 0 2px 11px 0 #ce3e59;
                `}
                className="web_white">
                Schedule
              </button>
            </article>
          ))}
        </div>
      </Background>
    </Layout>
  )
}

export default Demo
